import { goBackNTimes, HubbleRoute, pushPage } from "~/shared_states/modal";
import { CollectPhoneNumber } from "./collect_phone";
import { CollectGiftOtpProps, CollectOtp } from "./collect_otp";
import { clientRepo } from "~/server/apis/client_repo";
import {
  DeliveryMethod,
  GiftVoucherDetailsResponse,
} from "~/server/types/gift";
import { HubbleEvent, webEventManager } from "~/data/events";

export function verifyGift<T>(props: {
  onVerificationSuccess: (response: T) => void;
  giftId: string;
  deviceVerificationToken: string;
  deliveryMethod: DeliveryMethod;
  defaultEventProps?: Record<string, any>;
}) {
  pushPage(
    () => (
      <CollectPhoneNumber
        submitPhoneNumber={async (number) => {
          webEventManager.sendEvent(
            HubbleEvent.GIFT_VERIFICATION_SUBMIT_IDENTIFIER,
            {
              ...props.defaultEventProps,
              type: "phone_number",
              collectedIdenitifer: number,
            }
          );
          return await clientRepo.sendOtpForGiftBox({
            id: props.giftId,
            identifier: number,
            deviceVerificationToken: props.deviceVerificationToken,
          });
        }}
        onValidPhoneNumberSubmitted={(phoneNumber, otpToken) => {
          collectOtp<T>({
            phoneNumber: phoneNumber,
            submitOtp: async (submitOtpProps) => {
              webEventManager.sendEvent(
                HubbleEvent.GIFT_VERIFICATION_SUBMIT_OTP,
                {
                  ...props.defaultEventProps,
                  type: "otp",
                  otp: submitOtpProps.otp,
                  otpToken: submitOtpProps.otpToken,
                  collectedIdenitifer: phoneNumber,
                }
              );
              return clientRepo.openGiftBox({
                id: props.giftId,
                deviceVerificationToken: props.deviceVerificationToken,
                otp: submitOtpProps.otp,
                otpToken: submitOtpProps.otpToken,
              }) as T;
            },
            onVerificationSuccess: props.onVerificationSuccess,
            otpToken: otpToken,
            resendOtp: async (number) => {
              return await clientRepo.sendOtpForGiftBox({
                id: props.giftId,
                identifier: number,
                deviceVerificationToken: props.deviceVerificationToken,
              });
            },
          });
        }}
        subtitle={
          props.deliveryMethod === "WHATSAPP"
            ? "Card details will be sent to your Whatsapp"
            : "Please note that you can’t change this later"
        }
      />
    ),
    {
      isModal: true,
      disableCloseButton: true,
      preventClose: false,
      enterAnimation: false,
    },
    HubbleRoute.COLLECT_PHONE_NUMBER_GIFTING
  );
}

export function collectOtp<T>(props: CollectGiftOtpProps<T>) {
  pushPage(
    () => (
      <CollectOtp
        phoneNumber={props.phoneNumber}
        submitOtp={props.submitOtp}
        onVerificationSuccess={async (response) => {
          props.onVerificationSuccess(response);
        }}
        otpToken={props.otpToken}
        resendOtp={props.resendOtp}
        buildSubtitle={props.buildSubtitle}
      />
    ),
    {
      isModal: true,
      disableCloseButton: true,
      preventClose: false,
      enterAnimation: false,
    },
    HubbleRoute.COLLECT_OTP_GIFTING
  );
}

export type SubmitPhoneNumberProps = {
  otpToken: string;
};
